import logo from './vb-logo.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Some Amazing Velvety Bath Bathbomb goodness coming soon!
        </p>
        <p> 
          In the meantime, you can reach us using our socials.
        </p>
        <a
          className="App-link"
          href="https://www.instagram.com/velvetybath/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="App-link"
          href="mailto:velvetybath@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email
        </a>
      </header>
      <footer className="App-footer">
        Copyright Velvetybath 2023
      </footer>
    </div>
  );
}

export default App;
